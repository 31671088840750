
import {
  computed, getCurrentInstance, onMounted, reactive, ref
} from 'vue'
import {
  useStore
} from 'vuex'
import { environment } from '@/utils'
import { claimUseCase } from '@/domain/usecase'
import rupiahFormat from '@/utils/helpers/rupiahFormat'
import Lightgallery from '@/views/shared-components/lightgallery/altIndex.vue'
import moment from 'moment'

export default {
  name: 'ModalpaymentForm',
  components: {
    Lightgallery,
  },
  props: {
    penaltyId: {
      default: null
    }
  },
  emits: ['reloadHeaderTable'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const dataSource = ref([]) as any
    const isLoading = ref(false)
    const propertiesProps = reactive(props)
    const penaltyId = computed(() => propertiesProps.penaltyId) as any
    const filters = {
      pageNumber: 1,
      pageSize: 10,
      totalRecords: 0,
      search: '',
      filterField: ['title'],
      custom: [['orderBy', '=', 'CreatedAt desc']] as any
    }

    const getDetailData = async () => {
      store.dispatch('showLoading')
      const response = await claimUseCase.getPenaltyPayment(penaltyId.value, filters)
      if (response.error) {
        $toast.add({
          severity: 'error', detail: 'Gagal mengambil data. Silahkan coba kembali.', group: 'bc', life: 3000
        })
      } else {
        dataSource.value = response.result.Data.map((data: any) => {
          const obj = {
            ...data,
            // Image: `${environment.getApiBase()}${data.ImageAttachment}`,
            Image: [{
              src: `${environment.getApiBase()}${data.ImageAttachment}`,
              imgSrc: `${environment.getApiBase()}${data.ImageAttachment}`,
              alt: `${data.Id}`
            }]
          }
          return obj
        })
        filters.totalRecords = dataSource.value.length
        console.log('dataDetail', dataSource.value)
      }
      store.dispatch('hideLoading')
    }

    const onPage = (event: any) => {
      filters.pageNumber = event.page + 1
      getDetailData()
    }

    const reloadHeaderTable = () => {
      emit('reloadHeaderTable')
    }

    const onRejectAct = (id: any) => {
      console.log('reject')
      $confirm.require({
        header: 'Reject Pembayaran Klaim',
        message: 'Apakah anda yakin ingin melakukan reject pembayaran klaim, lanjutkan ?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const payload = {
            reason: '-'
          }
          const response = await claimUseCase.rejectPenaltyPayment(id, payload)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
            })
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
            })
            reloadHeaderTable()
            getDetailData()
          }
          store.dispatch('hideLoading')
        }
      })
    }

    const onApproveAct = (id: any) => {
      console.log('approve')
      $confirm.require({
        header: 'Approve Pembayaran Klaim',
        message: 'Apakah anda yakin ingin melakukan approve pembayaran klaim, lanjutkan ?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await claimUseCase.approvePenaltyPayment(id)
          if (response.error) {
            $toast.add({
              severity: 'error', detail: $strInd.toast.errorSubmit, group: 'bc', life: 3000
            })
          } else {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
            })
            reloadHeaderTable()
            getDetailData()
          }
          store.dispatch('hideLoading')
        }
      })
    }

    onMounted(() => {
      console.log('xxx')
      getDetailData()
    })

    return {
      dataSource,
      filters,
      onPage,
      rupiahFormat,
      onRejectAct,
      onApproveAct,
      moment
    }
  }
}
