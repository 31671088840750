
import {
  computed, getCurrentInstance, onMounted, reactive, ref
} from 'vue'
import {
  useStore
} from 'vuex'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import { compressImage } from '@/plugins/compressor'
import VueUploadComponent from 'vue-upload-component'
import { claimUseCase, receiveItemFileUseCase } from '@/domain/usecase'

export default {
  name: 'ModalpaymentForm',
  components: {
    FileUpload: VueUploadComponent,
    Form,
    Field
  },
  props: {
    penaltyId: {
      default: null
    }
  },
  emits: ['closeSidebar', 'reloadTable'],
  setup(props: any, { emit }: any) {
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const schemaForm = Yup.object({
      Nominal: Yup.number().required('Nominal harus diisi.').min(0, 'Nominal tidak boleh dibawah 0.'),
      Note: Yup.string().required('Keterangan harus diisi.')
    })
    const dataForm = ref({
      Nominal: 0,
      Note: null
    })
    const isLoading = ref(false)
    const propertiesProps = reactive(props)
    const penaltyId = computed(() => propertiesProps.penaltyId) as any
    const tempUploadPayment = ref(null) as any
    const uploadPayment = ref({
      src: null,
      name: null
    }) as any
    const afterUploadImgPath = ref(null) as any
    const pushListImage = async (item: any) => {
      // console.log('ok from ', from)
      let file = item
      file = await compressImage(file)
      file = new File([file], file.name)
      console.log('file to compress', file)
      uploadPayment.value.src = URL.createObjectURL(item)
      uploadPayment.value.image = file
    }
    const onUploadPayment = (newFile: any, oldFile: any) => {
      console.log('file', newFile)
      pushListImage(newFile.file)
    }
    const removeImage = () => {
      uploadPayment.value.src = null
      uploadPayment.value.image = null
    }
    const processUploadFile = async () => {
      if (await uploadPayment.value.src !== null) {
        const formPaymentImg = new FormData()
        formPaymentImg.append('file', uploadPayment.value.image)

        await receiveItemFileUseCase.submitFileSingle(formPaymentImg).then((res) => {
          // console.log('hasil upload res', res)
          if (!res.error) {
            afterUploadImgPath.value = {
              Path: res.result.Filename,
            } as any
          } else {
            $toast.add({
              severity: 'error',
              detail: 'Gagal upload KTP',
              group: 'bc',
              life: 3000
            })
          }
        })
      }
    }
    const closeSidebar = () => {
      emit('closeSidebar')
    }
    const reloadTable = () => {
      emit('reloadTable')
    }
    const submitData = async (values: any) => {
      console.log('img', uploadPayment.value)
      console.log('submit', values)
      $confirm.require({
        header: 'Proses Pembayaran Klaim',
        message: 'Apakah anda yakin ingin melakukan proses pembayaran klaim, lanjutkan ?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          await processUploadFile()
          const payload = {
            ImageAttachment: afterUploadImgPath.value ? afterUploadImgPath.value?.Path : null,
            Amount: values.Nominal,
            PenaltyIds: [penaltyId.value]
          }
          console.log('payload', payload)
          const response = await claimUseCase.submitDataPayment(payload)
          console.log('res', response)
          if (!response.error) {
            $toast.add({
              severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
            })
            closeSidebar()
            reloadTable()
          } else {
            $toast.add({
              severity: 'error', detail: response.message ?? $strInd.toast.errorSubmit, group: 'bc', life: 3000
            })
          }
          store.dispatch('hideLoading')
        }
      })
    }
    onMounted(() => {
      console.log('xxx')
    })

    return {
      submitData,
      removeImage,
      onUploadPayment,
      uploadPayment,
      tempUploadPayment,
      isLoading,
      dataForm,
      schemaForm,
      closeSidebar
    }
  }
}

