
import { getCurrentInstance, onMounted, ref } from 'vue'
import {
  useStore
} from 'vuex'
import { Form, Field } from 'vee-validate'
import * as Yup from 'yup'
import MultiselectAsync from '@/views/shared-components/select/MultiselectAsync.vue'
import {
  agentUseCase, claimUseCase, labelAgentUseCase, labelUseCase
} from '@/domain/usecase'

export default {
  name: 'ModalAddClaimForm',
  components: {
    MultiselectAsync,
    Field,
    Form
  },
  props: {},
  emits: ['closeSidebar', 'reloadTable'],
  setup(props: any, { emit }: any) {
    const endpointItemCategory = '/backend/v2/mskategoribarangs'
    const app = getCurrentInstance()
    const {
      $confirm,
      $toast,
      $strInd
    } = app!.appContext.config.globalProperties
    const store = useStore()
    const groupLabel = ref([]) as any
    const agentOptions = ref([]) as any
    const labelStocksOptions = ref([]) as any
    const selectedAgent = ref(null) as any
    const selectedGroupLabel = ref(null) as any
    const isLoading = ref(true)
    const schemaForm = Yup.object({
      Nominal: Yup.string().required('Nominal harus diisi.').min(0, 'Nominal tidak boleh dibawah 0.'),
      Agent: Yup.mixed().required('Agent harus diisi.'),
      Note: Yup.string().required('Keterangan harus diisi.'),
      Color: Yup.mixed().required('Pilih label terlebih dahulu agar dapat memilih stok pada label'),
      Label: Yup.array().of(
        Yup.object().shape({
          KodeLabel: Yup.string().required('Pilih label dahulu.')
        })
      ).min(1, 'Minimal terdapat 1 label/resi')
    })
    const dataForm = ref({
      Nominal: 0,
      Agent: null,
      Note: null,
      Color: null,
      Label: [] as any
    })
    const filterAgent = {
      search: '',
      filterField: ['name'],
      pageSize: 20,
      pageNumber: 1,
      isSearchLowerCase: true,
      custom: []
    }
    const filterLabel = {
      search: '',
      filterField: ['kode'],
      pageSize: 20,
      pageNumber: 1,
      isSearchLowerCase: true,
      custom: []
    }
    const filterLabelStocks = {
      search: '',
      filterField: ['kodeLabel'],
      pageSize: 20,
      pageNumber: 1,
      isSearchLowerCase: true,
      custom: [] as any
    }
    const getAgentOptions = async () => {
      store.dispatch('showLoading')
      console.log('aaaa')
      const response = await agentUseCase.getAllAgentV2(filterAgent)
      agentOptions.value = response.result.map((data: any) => {
        const obj = {
          ...data,
          label: data.Name,
          value: data.Id
        }
        return obj
      })
      // console.log('options', agentOptions.value)
      store.dispatch('hideLoading')
      isLoading.value = false
    }

    const searchOptions = (val: any) => {
      if (val.length > 2 || val === '') {
        filterAgent.search = val
        getAgentOptions()
      }
    }

    const getGroupLabelOptions = async () => {
      store.dispatch('showLoading')
      console.log('aaaa')
      const response = await labelUseCase.getAll(filterLabel)
      groupLabel.value = response.result.Data.map((x: any) => ({
        ...x,
        color: x.Warna,
        label: x.Nama,
        value: { ...x }
      }))
      // console.log('options', agentOptions.value)
      store.dispatch('hideLoading')
    }

    const getAgentLabelStocksOptions = async () => {
      store.dispatch('showLoading')

      filterLabelStocks.custom = [['isPicked', '=', false], ['labelId', '=', selectedGroupLabel.value?.Id]]
      console.log('aaaa', filterLabelStocks)
      const response = await labelAgentUseCase.getStockLabelAgent(selectedAgent.value.Id, filterLabelStocks)
      labelStocksOptions.value = response.result.Data.map((x: any) => ({
        ...x,
        label: x.KodeLabel,
        value: { ...x }
      }))
      // console.log('options', agentOptions.value)
      store.dispatch('hideLoading')
    }

    const labelStocksSerach = (val: any) => {
      console.log('search', val)
      if (val.length > 2) {
        labelStocksOptions.value = []
        filterLabelStocks.search = val
        getAgentLabelStocksOptions()
      }
    }

    const setSelectedGroupLabel = (val: any) => {
      selectedGroupLabel.value = val
      if (val) {
        labelStocksOptions.value = []
        getAgentLabelStocksOptions()
      }
      console.log('groupLabel', val)
    }

    const setSelectedAgent = (val: any) => {
      selectedAgent.value = val
    }

    const closeSidebar = () => {
      emit('closeSidebar')
    }
    const reloadTable = () => {
      emit('reloadTable')
    }
    const submitData = (values: any) => {
      console.log('submit', values)
      const payload = {
        type: 'LostLabel',
        references: [],
        agentInChargeId: values.Agent.Id,
        amount: values.Nominal,
        recipients: [] as any
      }
      const labels = [] as any
      // const labels = values.Label.map((data: any) => `${data.KodeLabel}${values.Color.Warna}`)
      // eslint-disable-next-line array-callback-return
      values.Label.map((data: any) => {
        const obj = {
          externalId: data.Id,
          name: data.KodeLabel,
          attributes: [
            {
              type: 'COLOR',
              value: values.Color.Warna
            }
          ]
        }
        labels.push(obj)
      })
      payload.references = labels

      const agentObj = {
        userId: values.Agent.Id,
        email: null,
        phoneNumber: null,
        name: values.Agent.Name,
        amount: values.Nominal
      }
      payload.recipients.push(agentObj)
      console.log('payload', payload)
      $confirm.require({
        header: 'Proses Tambah Klaim',
        message: 'Apakah anda yakin ingin melakukan proses tambah klaim, lanjutkan ?',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
          store.dispatch('showLoading')
          const response = await claimUseCase.submitDataPenalty(payload)
          console.log('res', response)
          if (!response.error) {
            closeSidebar()
            reloadTable()
            $toast.add({
              severity: 'success', detail: $strInd.toast.successSubmit, group: 'bc', life: 3000
            })
          } else {
            $toast.add({
              severity: 'error', detail: response.message ?? $strInd.toast.errorSubmit, group: 'bc', life: 3000
            })
          }
          store.dispatch('hideLoading')
        }
      })
    }
    const test = (val: any) => {
      console.log(val)
    }
    onMounted(() => {
      isLoading.value = true
      console.log('xxxxx')
      getGroupLabelOptions()
      getAgentOptions()
    })

    return {
      submitData,
      searchOptions,
      dataForm,
      schemaForm,
      endpointItemCategory,
      isLoading,
      closeSidebar,
      agentOptions,
      groupLabel,
      test,
      setSelectedGroupLabel,
      labelStocksSerach,
      labelStocksOptions,
      setSelectedAgent
    }
  }
}

